import React from 'react';
import Layout from '../components/layout';
import SEO from '../components/seo';
import PageHeader from '../components/reusable/PageHeader';
import { useStaticQuery, graphql } from 'gatsby';
import styled from 'styled-components';
import { device } from '../constants/mediaQueries';


const CasesStyleWrap = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  > div {
    width: 100%;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      padding: 4rem;
      background-position: center;
      background-size: cover;
      margin: 0.5rem;
    }
  }

  .color-overlay {
    width: 350px;
    height: 350px;
    padding: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    opacity: 0.8;
    transition: all .2s ease-in-out;

    &:hover {
      transform: scale(1.1);
    }
  }

  @media ${device.deviceS}{
      flex-direction: row;
      flex-wrap: wrap;

      > div {
        width: 50%;
      }

      .color-overlay {
        width: 205px;
        height: 205px;
      }
  }

  @media ${device.deviceM}{
    .color-overlay {
      width: 250px;
      height: 250px;
    }
  }

  @media ${device.deviceL}{
    > div {
      width: 33%;
    }
    .color-overlay {
      width: 205px;
      height: 205px;
    }
  }
`;


const Cases = () => {
  const { cases } = useStaticQuery(graphql`
    {
      cases: allContentfulCases(sort: {fields: orderNumber, order: ASC}) {
        edges {
          node {
            title
            orderNumber
            background {
              file {
                url
              }
            }
            logo {
              file {
                url
              }
            }
            casePdf {
              file {
                url
              }
            }
            customOverlayColor
          }
        }
      }
    }
  `);
    console.log(cases);
  return (
    <Layout>
      <SEO title="Cases" />
      <PageHeader title="Cases" />
      <div className="container">
        <CasesStyleWrap>
            {cases.edges.map(({ node }) => {
              return (
                <div key={node.title}>
                  <a href={node.casePdf.file.url} target="_blank" rel="noreferrer" style={{backgroundImage: `url(${node.background.file.url})`}}>
                      <div className="color-overlay" style={{backgroundColor: (node.customOverlayColor ?  node.customOverlayColor : '#f7f7f7')}}>
                        <img src={node.logo.file.url} alt={node.title} />
                      </div>
                  </a>
                </div>
              );
            })}
        </CasesStyleWrap>
      </div>
    </Layout>
  );
}
  
  
  


export default Cases